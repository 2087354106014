import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";
import cctvReducer from "./cctvSlice";
import annotationReducer from "./Annotation";
import incidentReducer from "./incidentSlice";
import userReducer from "./userSlice";
import dashboardReducer from "./dashboardSlice";
import auditReducer from "./AuditTrailSlice";
import ECMDrainReducer from "./ECMDrainSlice";
import SitrepReducer from "./SitrepSlice";
import weatherReducer from "./weatherSlice";
import inboxReducer from "./InboxSlice";
import WorkReducer from "./work_declarationSlice";
import generalConfigReducer from "./generalConfigSlice";
import ImageReducer from "./ImageSlice";
import WMSReducer from "./WMS";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    cctvs: cctvReducer,
    incidents: incidentReducer,
    users: userReducer,
    annotation: annotationReducer,
    dashboard: dashboardReducer,
    audit: auditReducer,
    ECMDrain: ECMDrainReducer,
    sitrep: SitrepReducer,
    weatherData: weatherReducer,
    inbox: inboxReducer,
    workList: WorkReducer,
    generalConfig: generalConfigReducer,
    Image: ImageReducer,
    wms: WMSReducer,
  }),
  middleware: [...getDefaultMiddleware(), routerMiddleware],
});

export const history = createReduxHistory(store);
