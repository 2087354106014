import React, { useEffect, useState } from "react";
import MobileHeaderFiltered from "../../../components/mobileHeaderFiltered/mobileHeaderFiltered";
import {
  CATEGORY_ROUTE,
  OHS_INSPECTION_CREATE_ROUTE,
  OHS_INSPECTION_DETAIL_ROUTE,
  OHS_INSPECTION_EDIT_ROUTE,
} from "../../../routes";
import { SelectTable } from "../../../components/table/tablewithcheckbox";
import FilterIcon from "../../../assets/images/cctv/filter.svg";
import ExportIcon from "../../../assets/images/OHS/Export.svg";
import ColumnChange from "../../../assets/images/OHS/ColumnChange.svg";
import SearchIcon from "../../../assets/images/OHS/SearchIcon.svg";
import { Dialog } from "@progress/kendo-react-dialogs";
import CrossIcon from "../../../assets/images/Icon/Cross_black.svg";
import DragIcon from "../../../assets/images/OHS/DragIcon.svg";
import CreateIcon from "../../../assets/images/OHS/CreateIcon.svg";
import { Checkbox, TextBox } from "@progress/kendo-react-inputs";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { constData, ConfigData } from "./ohs_configData";
import { Button } from "@progress/kendo-react-buttons";
import { plusIcon } from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router";
import api from "../../../config/api";
import config from "../../../config/config";
import { getCurrentLoggedInSessionTokenWithPromise } from "../../../config/userPool";
import { Loader } from "@progress/kendo-react-indicators";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { OHS_Filter } from "../OHS_Filter";
import {
  getUrlFromPath,
  getParamsFromQuery,
} from "../form/form_designer/utils/url";
import jsonPath, { value } from "jsonpath";
import ConfirmMsg from "../../../components/confirmMsg";
import FullscreenLoader from "../../../components/fullScreenLoader";
import moment from "moment";
import OHSListSearch from "../OHS_Filter/search";
import { Pagination } from "./pagination";

const data = constData;

// const moduleConstData = ConfigData;

export const OHS_SIDEBAR_WEB = () => {
  const navigate = useNavigate();
  let current_user = JSON.parse(localStorage.getItem("current_user"));
  let filteredData = localStorage.getItem("OHSFILTER")
    ? JSON.parse(localStorage.getItem("OHSFILTER"))
    : {};
  let savedRow = JSON.parse(localStorage.getItem("OHSTABLEROW"));
  const [showColumnBox, setShowColumnBox] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [modules, setMoudles] = useState([]);
  const [commonToolEdit, setCommonToolEdit] = useState([]);
  const [moduleConstData, setModulesConstData] = useState(ConfigData);
  const [tempModule, setTempModule] = React.useState(ConfigData);
  const [columnOrder, setColumnOrder] = useState(ConfigData);
  const [showColumnItems, setShowColumnItems] = useState(ConfigData);
  const [filterLoader, setFilterLoader] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [exportIDS, setExportIDS] = useState([]);
  const [page, setPage] = useState(1);
  const [showExport, setShowExport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [showExportError, setShowExportError] = useState(false);
  const [columnFilterValue, setColumnFilterValue] = useState(
    filteredData.filteredItem ? filteredData.filteredItem : {}
  );
  const [filterLoading, setFilterLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [columnFilter, setColumnFilter] = useState({});
  const [showPdfOption, setShowPdfOption] = useState(false);
  const [showEmailPopUp, setShowEmailPopup] = useState(false);
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showPDFDownload, setShowPDFDownload] = useState(false);
  const [exportType, setExportType] = useState("");
  const [widgetSaveLoading, setWidgetSaveLoading] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showExportBtn, setShowExportBtn] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [rowCount, setRowCount] = useState(savedRow ? savedRow : 15);
  const [FilterJSON, setFilterJSON] = useState({});
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [pageChangeLoading, setPageChangeLoading] = useState(false);
  const [popupItem, setPopupItem] = useState({
    excel: false,
    pdf: false,
  });
  const [showCreateBtn, setShowCreateBtn] = useState(false);
  const [emailData, setEmailData] = useState({
    subject: "",
    recipient: "",
    content: "",
  });
  const [filterValue, setFilterValue] = useState({
    user_group: "",
    package: [],
    work_zone: "",
    type_of_work_activity: "",
    status: "",
    source_of_observation: "",
    significant_level_of_observation: "",
    type_of_risk: "",
    company_carrying: "",
    hazards: "",
    from_date: "",
    to_date: "",
    potential_consequences: "",
    search_text: "",
  });
  const [filterError, setFilterError] = useState({
    isError: false,
    startDate: "",
    endDate: "",
  });
  const {
    GET_OHS_ENDPOINT,
    GET_OHS_FILTER_ENDPOINT,
    OHS_EXPORT,
    OHS_EMAIL_ENDPOINT,
    GET_USER_ALT_ENDPOINT,
    UPDATE_USER_DETAIL,
    OHS_JSON_ENDPOINT,
  } = config.api_endpoint;
  const {
    VIEW_OHS,
    VIEW_OHS_LIMITED,
    CREATE_OHS_LIMITED,
    CREATE_OHS,
    OHS_EXPORT_CONTROL,
    OHS_EXPORT_CONTROL_LIMITED,
  } = config.permissions;
  const handleShowColumnBox = () => {
    setShowColumnBox(!showColumnBox);
  };
  const handleSearchIconClicked = () => {
    setShowSearchBox(!showSearchBox);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };
  const onExportIconClicked = () => {
    setShowOption(!showOption);
  };
  const [listingData, setListingData] = useState([]);
  const [loading, setLoading] = useState(false);

  const filterURL = [
    {
      id: "work_zone",
      apiPath: "/api-ohs/input-selection?key=Work Zone",
    },
    {
      id: "type_of_work_activity",
      apiPath: "/api-ohs/input-selection?key=Type of Work Activity",
    },
    {
      id: "package",
      apiPath: "/api-users/getUser",
      jsonPath: "$.data.package[*]",
    },
    {
      id: "company_carrying",
      apiPath: "/api-work-declaration/work_declaration/company",
      jsonPath: "$.data[*]",
    },
    {
      id: "hazards",
      apiPath: "/api-ohs/input-selection?key=Hazards",
    },
    {
      id: "potential_consequences",
      apiPath: "/api-ohs/input-selection?key=Potential Consequences",
    },
  ];

  const icons = showExportBtn
    ? [
        {
          logo: SearchIcon,
          handleClicked: handleSearchIconClicked,
          show: true,
        },
        {
          logo: ColumnChange,
          show: true,
          handleClicked: handleShowColumnBox,
        },
        {
          logo: ExportIcon,
          show: true,
          handleClicked: onExportIconClicked,
        },
        // {
        //   logo: FilterIcon,
        //   show: true,
        //   handleClicked: handleShowFilter,
        // },
      ]
    : [
        {
          logo: SearchIcon,
          handleClicked: handleSearchIconClicked,
          show: true,
        },
        {
          logo: ColumnChange,
          show: true,
          handleClicked: handleShowColumnBox,
        },
      ];

  const shouldAccessAll = (user) => {
    if (user.permissions.indexOf(VIEW_OHS_LIMITED) > -1) {
      return false;
    } else if (user.permissions.indexOf(VIEW_OHS) > -1) {
      return true;
    }
    return false;
  };

  const moduleOnOFF = (data) => {
    const tempFind = tempModule.find((i) => i && i.item === data);
    if (tempFind) {
      const filter = tempModule.filter((i) => i.item !== data);
      setTempModule(filter);
    } else {
      const find = moduleConstData.find((i) => i.item === data);
      setTempModule((old) => [...old, find]);
    }
  };

  const getTaskPos = (id) => columnOrder.findIndex((mod) => mod.id === id);
  const arrayMoves = (active, over) => {
    const originalPos = getTaskPos(active);
    const newPos = getTaskPos(over);

    return arrayMove(columnOrder, originalPos, newPos);
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (!active || !over) return;
    if (active.id === over.id) return;
    // setWidgetSaveLoading(true);
    setColumnOrder((module) => {
      const originalPos = getTaskPos(active.id);
      const newPos = getTaskPos(over.id);

      return arrayMove(module, originalPos, newPos);
    });
    setTempModule((module) => {
      const originalPos = getTaskPos(active.id);
      const newPos = getTaskPos(over.id);

      return arrayMove(module, originalPos, newPos);
    });

    const ArrayItem = arrayMoves(active.id, over.id);
    // SaveToUSERData(ArrayItem);
    // SaveToUSERData([
    //   {
    //     commonTool: commonToolEdit,
    //     module: modules,
    //     OHSTable: ArrayItem,
    //   },
    // ]);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 8,
        // distance: 100,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const columnCheckBox = (id, check) => {
    const columnUpdate = columnOrder.find((i) => i.id === id);
    if (columnUpdate) {
      columnUpdate.isShow = !columnUpdate.isShow;
    }
    setColumnOrder(columnOrder);
  };

  const Task = ({ id, item, isShow }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });
    const style = {
      transition,
      transform: CSS.Transform.toString(transform),
    };

    return (
      <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
        <div key={id} className="OHS_Column_Checkbox_child_item_box">
          <div
            className="OHS_Column_Checkbox_child_item_first_child"
            onClick={() => moduleOnOFF(id)}
          >
            <img src={DragIcon} style={{ cursor: "move" }} />
            {item}
          </div>
          <div>
            <Checkbox
              className="OHS_Column_Checkbox"
              defaultChecked={isShow}
              // id={`${_data.id ? _data.id : ""}`}
              // // data-id={`${_data.id}`}
              onClick={(e) => columnCheckBox(id, e.target.checked)}
            />
          </div>
        </div>
      </div>
    );
  };

  const CreatePage = () => {
    // navigate(
    //   `${OHS_INSPECTION_EDIT_ROUTE}?id=MjAyNC0wOC0xNiAwMDoyNjo0NC42NjU5NTUrMDg6MDA3MWY4NGJhY2RlOTI0YmU2YjM4ZDdjNGI3NzZmNzAzNA==`
    // );
    navigate(OHS_INSPECTION_CREATE_ROUTE);
  };

  const filterDataAPI = async (url) => {
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    try {
    } catch (err) {
      console.log(err);
    }
    const endpointUrl = getUrlFromPath(url.apiPath);
    const endpoint = endpointUrl?.pathname || url.apiPath;
    let inputs = await api.get(
      endpoint,
      {
        uid: current_user["cognito:username"],
        // Append parameters from the endpointUrl's query
        ...getParamsFromQuery(endpointUrl),
      },
      token
    );
    if (url.jsonPath) {
      inputs = jsonPath.query(inputs, url.jsonPath);
    }
    if (inputs && !Array.isArray(inputs)) {
      const { status, status_code, ...objArray } = inputs;
      inputs = Object.values(objArray);
    }
    if (inputs?.length === 0) {
      inputs = ["Not available"];
    }

    return inputs;
  };

  const getTableColumndata = (apidata) => {
    if (apidata === undefined) return;
    if (columnOrder.length > 0) {
      let columnFilterData = {};
      columnOrder.map((i) => {
        if (columnFilterData[i.id] === undefined) {
          columnFilterData = {
            ...columnFilterData,
            [i.id]: [],
          };
        }
        apidata.map((data) => {
          if (columnFilterData[i.id]?.length > 0) {
            if (i.id.includes(".")) {
              const split = i.id.split(".");
              if (
                data[split[0]] !== null &&
                data[split[0]]?.[split[1]] !== undefined
              ) {
                if (Array.isArray(data[split[0]]?.[split[1]])) {
                  data[split[0]]?.[split[1]].map((k) => {
                    const findData = columnFilterData[i.id].find(
                      (l) => l === k
                    );
                    !findData && columnFilterData[i.id].push(k);
                  });
                } else {
                  const findFor = columnFilterData[i.id].find(
                    (l) => l === data[split[0]]?.[split[1]]
                  );
                  !findFor &&
                    columnFilterData[i.id].push(data[split[0]]?.[split[1]]);
                }
              }
            } else {
              if (
                !columnFilterData[i.id].find((j) =>
                  typeof j === "object"
                    ? j.id === data?.[i.id]?.id
                    : j === data[i.id]
                )
              ) {
                if (data[i.id] !== null) {
                  columnFilterData[i.id].push(data[i.id]);
                }
              }
            }
          } else {
            if (i.id.includes(".")) {
              const split = i.id.split(".");
              if (
                data[split[0]] !== null &&
                data[split[0]]?.[split[1]] !== undefined
              ) {
                if (Array.isArray(data[split[0]]?.[split[1]])) {
                  console.log(data[split[0]]?.[split[1]]);
                  columnFilterData[i.id].push(...data[split[0]]?.[split[1]]);
                } else {
                  columnFilterData[i.id].push(data[split[0]]?.[split[1]]);
                }
              }
            } else {
              if (data[i.id] !== null) {
                columnFilterData[i.id].push(data[i.id]);
              }
            }
          }
        });
      });
      return columnFilterData;
    }
  };

  useEffect(() => {
    async function getData() {
      setLoading(true);
      let filterAPICall = {
        significant_level_of_observation: [
          "Compliance",
          "Observation",
          "Significant Observation",
          "No Activity",
        ],
        type_of_risk: ["Aerodrome", "Environmental", "WSH"],
      };
      await filterURL.map(async (url) => {
        const result = await filterDataAPI(url);
        if (result) {
          filterAPICall = { ...filterAPICall, [url.id]: result };
          setFilterData(filterAPICall);
        }
      });
      const userData = JSON.parse(localStorage.getItem("current_user"));
      if (
        userData.permissions.filter((x) =>
          [CREATE_OHS, CREATE_OHS_LIMITED].includes(x)
        ).length > 0
      ) {
        setShowCreateBtn(true);
      }
      if (
        userData.permissions.filter((x) =>
          [OHS_EXPORT_CONTROL, OHS_EXPORT_CONTROL_LIMITED].includes(x)
        ).length > 0
      ) {
        setShowExportBtn(true);
      }
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      api
        .get(
          GET_USER_ALT_ENDPOINT,
          {
            uid: userData.sub,
          },
          token
        )
        .then((res) => {
          if (res.status_code === 200) {
            setCommonToolEdit(
              res.data.widgets_config[0]
                ? res.data.widgets_config[0].commonTool
                : []
            );
            setMoudles(
              res.data.widgets_config[0]
                ? res.data.widgets_config[0].module
                : []
            );
            // setModulesConstData(
            //   res.data.widgets_config[0] && res.data.widgets_config[0].OHSTable
            //     ? res.data.widgets_config[0].OHSTable
            //     : ConfigData
            // );
            // setTempModule(
            //   res.data.widgets_config[0] && res.data.widgets_config[0].OHSTable
            //     ? res.data.widgets_config[0].OHSTable
            //     : ConfigData
            // );
            if (
              res.data.widgets_config[0] &&
              res.data.widgets_config[0].OHSTable
            ) {
              if (
                res.data.widgets_config[0].OHSTable.length === ConfigData.length
              ) {
                setColumnOrder(
                  res.data.widgets_config[0] &&
                    res.data.widgets_config[0].OHSTable
                    ? res.data.widgets_config[0].OHSTable
                    : ConfigData
                );
                setShowColumnItems(
                  res.data.widgets_config[0] &&
                    res.data.widgets_config[0].OHSTable
                    ? res.data.widgets_config[0].OHSTable
                    : ConfigData
                );
              } else {
                setColumnOrder(ConfigData);
                setShowColumnItems(ConfigData);
              }
            }

            api.getS3(OHS_JSON_ENDPOINT, {}, token).then((res) => {
              if (res.status_code === 200) {
                let package_temp = [];
                if (!shouldAccessAll(current_user)) {
                  res.package.map((i) => {
                    if (current_user.package.find((j) => j === i)) {
                      package_temp.push(i);
                    }
                  });
                } else {
                  package_temp = res.package;
                }
                setFilterJSON({ ...res, package: package_temp });
                console.log("JSON", { ...res, package: package_temp });
              } else {
                alert("Error fetching JSON data. Please try again later!");
              }
            });

            api
              .get(
                GET_OHS_FILTER_ENDPOINT,
                getFilterParams(),
                // {
                //   page: 1,
                //   page_size: rowCount,
                //   package: !shouldAccessAll(current_user)
                //     ? current_user["package"].join(",")
                //     : "",
                //   created_uid: localStorage.getItem("username"),
                // },
                token
              )
              .then((res) => {
                if (res.details === "success") {
                  setListingData([...res.data.items]);
                  console.log();
                  setColumnFilter(getTableColumndata(res.data.items));
                }
                let dataCount = 0;
                Object.keys(res.data.count).map((i) => {
                  dataCount = dataCount + res.data.count?.[i];
                });
                setTotalDataCount(dataCount);
                setHasMore(res.data.newPage);
                setTotalPage(res.data.total_page);
                setLoading(false);
              });
          }
        });
    }

    getData();
  }, []);

  const dateErrorChecker = (value, type) => {
    if (type === "from_date") {
      let date = value;
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      if (filterValue.to_date !== "" && filterValue.to_date < date) {
        setFilterError({
          ...filterError,
          isError: true,
          startDate: "Start date should not be later than End date!",
          endDate: "End date should not be earlier than Start date!",
        });
      }
      if (filterValue.to_date === "") {
        setFilterError({
          ...filterError,
          isError: true,
          startDate: "",
          endDate: "End date field need!",
        });
      }
      if (filterValue.to_date !== "" && filterValue.to_date > date) {
        setFilterError({
          ...filterError,
          isError: false,
          startDate: "",
          endDate: "",
        });
      }
    } else {
      let date = value;
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);
      // setEndDate(date);
      if (filterValue.from_date !== "" && date < filterValue.from_date) {
        setFilterError({
          ...filterError,
          isError: true,
          startDate: "Start date should not be later than End date!",
          endDate: "End date should not be earlier than Start date!",
        });
      }
      if (filterValue.from_date === "") {
        setFilterError({
          ...filterError,
          isError: true,
          endDate: "",
          startDate: "Start date field need!",
        });
      }
      if (filterValue.from_date !== "" && date > filterValue.from_date) {
        setFilterError({
          ...filterError,
          isError: false,
          endDate: "",
          startDate: "",
        });
      }
    }
  };

  const onChangeFilter = async (value, type) => {
    if (type === "user_group") {
      setFilterLoader(true);
      const url =
        value === "Project Team"
          ? { apiPath: "/api-ohs/input-selection?key=Source of Observation 1" }
          : { apiPath: "/api-ohs/input-selection?key=Source of Observation 2" };
      const result = await filterDataAPI(url);
      if (result) {
        setFilterData({
          ...filterData,
          source_of_observation: result,
        });
        setFilterLoader(false);
      }
      setFilterValue({
        ...filterValue,
        [type]: value,
        source_of_observation: "",
      });
    } else if (type === "from_date" || type === "to_date") {
      dateErrorChecker(value, type);
      setFilterValue({
        ...filterValue,
        [type]: value,
      });
    } else {
      setFilterValue({
        ...filterValue,
        [type]: value,
      });
    }
  };

  const getFilterParams = (options = {}) => {
    console.log(options);
    let current_user = JSON.parse(localStorage.getItem("current_user"));
    let filterParams = {
      page: 1,
      page_size: rowCount,
      package: !shouldAccessAll(current_user)
        ? current_user["package"].join(",")
        : "",
      created_uid: localStorage.getItem("username"),
    };

    if (filterValue.to_date !== "" && filterValue.from_date === "") {
      setFilterError({
        ...filterError,
        isError: true,
        startDate: "Start date field need!",
        endDate: "",
      });
      return;
    }
    if (filterValue.from_date !== "" && filterValue.to_date === "") {
      setFilterError({
        ...filterError,
        isError: true,
        startDate: "",
        endDate: "End date field need!",
      });
      return;
    }
    if (filterValue.from_date > filterValue.to_date) {
      setFilterError({
        ...filterError,
        isError: true,
        startDate: "Start date should not be later than End date!",
        endDate: "End date should not be earlier than Start date!",
      });
      return;
    }
    if (options.newPageValue) {
      filterParams = {
        ...filterParams,
        page: options.newPageValue,
      };
    }
    if (options.row) {
      filterParams = {
        ...filterParams,
        page_size: options.row,
      };
    }
    if (filterValue.search_text !== "") {
      filterParams = {
        ...filterParams,
        text: filterValue.search_text,
      };
    }
    if (filterValue.from_date !== "") {
      filterParams = {
        ...filterParams,
        start_date: `${moment(filterValue.from_date).format("YYYY-MM-DD")}`,
      };
    }
    if (filterValue.to_date !== "") {
      filterParams = {
        ...filterParams,
        end_date: `${moment(filterValue.to_date).format("YYYY-MM-DD")}`,
      };
    }
    if (filterValue.package !== "") {
      filterParams = {
        ...filterParams,
        package:
          filterValue.package.length > 0
            ? filterValue.package.find((i) => i === "All")
              ? !shouldAccessAll(current_user)
                ? current_user["package"].join(",")
                : ""
              : filterValue.package.join(",")
            : !shouldAccessAll(current_user)
            ? current_user["package"].join(",")
            : "",
      };
    }
    if (filterValue.user_group !== "") {
      filterParams = {
        ...filterParams,
        user_group: filterValue.user_group,
      };
    }
    if (filterValue.work_zone !== "") {
      filterParams = {
        ...filterParams,
        work_zone: filterValue.work_zone,
      };
    }
    if (filterValue.type_of_work_activity !== "") {
      filterParams = {
        ...filterParams,
        type_of_work_activity: filterValue.type_of_work_activity,
      };
    }
    if (filterValue.type_of_risk !== "") {
      filterParams = {
        ...filterParams,
        type_of_risk: filterValue.type_of_risk,
      };
    }
    if (filterValue.status !== "") {
      filterParams = {
        ...filterParams,
        status: filterValue.status,
      };
    }
    if (filterValue.source_of_observation !== "") {
      filterParams = {
        ...filterParams,
        source_of_observation: filterValue.source_of_observation,
      };
    }
    if (filterValue.significant_level_of_observation !== "") {
      filterParams = {
        ...filterParams,
        significant_level_of_observation:
          filterValue.significant_level_of_observation,
      };
    }
    if (filterValue.company_carrying !== "") {
      filterParams = {
        ...filterParams,
        company_carrying: filterValue.company_carrying,
      };
    }
    if (filterValue.hazards !== "") {
      filterParams = {
        ...filterParams,
        hazards: filterValue.hazards,
      };
    }
    if (filterValue.potential_consequences !== "") {
      filterParams = {
        ...filterParams,
        potential_consequences: filterValue.potential_consequences,
      };
    }
    if (Object.keys(columnFilterValue).length > 0) {
      for (let i = 0; i < Object.keys(columnFilterValue).length; i++) {
        let key = Object.keys(columnFilterValue);
        const params_name = key[i].includes("checklist")
          ? key[i].replace(".", "_")
          : key[i];
        if (columnFilterValue?.[key[i]] !== null) {
          filterParams = {
            ...filterParams,
            [params_name]: Array.isArray(columnFilterValue?.[key[i]])
              ? columnFilterValue?.[key[i]].join(", ")
              : moment(columnFilterValue?.[key[i]]).format("YYYY-MM-DD"),
          };
        }
      }
    }

    return filterParams;
  };

  const keyDownHandler = (e) => {
    console.log(e.key);
    if (e.key === "Enter") {
      filterApply();
    }
  };

  const filterApply = async () => {
    console.log("filterData", columnFilterValue);
    setFilterLoading(true);
    setShowFilter(false);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api.get(GET_OHS_FILTER_ENDPOINT, getFilterParams(), token).then((res) => {
      let dataCount = 0;
      Object.keys(res.data.count).map((i) => {
        dataCount = dataCount + res.data.count?.[i];
      });
      setTotalDataCount(dataCount);
      setListingData([...res.data.items]);
      setHasMore(res.data.newPage);
      setTotalPage(res.data.total_page);
      setColumnFilter(getTableColumndata(res.data.items));
      setPage(1);
      setFilterLoading(false);
    });
  };

  const filterClear = async (search) => {
    let current_user = JSON.parse(localStorage.getItem("current_user"));
    setFilterLoading(true);
    if (search) {
      setFilterValue({
        ...filterValue,
        search_text: "",
      });
      let filterParams = {
        ...getFilterParams(),
      };
      if (filterParams.text) {
        delete filterParams.text;
      }
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const newPage = 1;
      api.get(GET_OHS_FILTER_ENDPOINT, filterParams, token).then((res) => {
        let dataCount = 0;
        Object.keys(res.data.count).map((i) => {
          dataCount = dataCount + res.data.count?.[i];
        });
        setTotalDataCount(dataCount);
        setPage(newPage);
        setListingData(res.data.items);
        setHasMore(res.data.newPage);
        setTotalPage(res.data.total_page);
        setColumnFilter(getTableColumndata(res.data.items));
        setFilterLoading(false);
      });
    } else {
      setShowFilter(false);
      let textString = filterValue.search_text;
      setFilterValue({
        user_group: "",
        package: [],
        work_zone: "",
        type_of_work_activity: "",
        status: "",
        source_of_observation: "",
        significant_level_of_observation: "",
        type_of_risk: "",
        company_carrying: "",
        hazards: "",
        from_date: "",
        to_date: "",
        potential_consequences: "",
        search_text: textString,
      });
      let filterParams = {
        page: 1,
        page_size: rowCount,
        package: !shouldAccessAll(current_user)
          ? current_user["package"].join(",")
          : "",
        created_uid: localStorage.getItem("username"),
      };
      if (textString !== "") {
        filterParams = {
          ...filterParams,
          text: textString,
        };
      }
      const token = await getCurrentLoggedInSessionTokenWithPromise();
      const newPage = 1;
      api.get(GET_OHS_FILTER_ENDPOINT, filterParams, token).then((res) => {
        let dataCount = 0;
        Object.keys(res.data.count).map((i) => {
          dataCount = dataCount + res.data.count?.[i];
        });
        setTotalDataCount(dataCount);
        setPage(newPage);
        setListingData(res.data.items);
        setHasMore(res.data.newPage);
        setTotalPage(res.data.total_page);
        setColumnFilter(getTableColumndata(res.data.items));
        setFilterLoading(false);
      });
    }
  };

  const handlePageChange = async (newPage) => {
    setPageChangeLoading(true);
    // console.log(pages);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    // const newPage = page + 1;
    api
      .get(
        GET_OHS_FILTER_ENDPOINT,
        getFilterParams({ newPageValue: newPage }),
        token
      )
      .then((res) => {
        let dataCount = 0;
        Object.keys(res.data.count).map((i) => {
          dataCount = dataCount + res.data.count?.[i];
        });
        setTotalDataCount(dataCount);
        setPage(newPage);
        setColumnFilter(
          getTableColumndata([...res.data.items])
          // getTableColumndata([...listingData, ...res.data.items])
        );
        setListingData([...res.data.items]);
        setHasMore(res.data.newPage);
        setPageChangeLoading(false);
      });
  };

  const handleRowChange = async (row) => {
    setRowCount(row);
    localStorage.setItem("OHSTABLEROW", JSON.stringify(row));
    setPageChangeLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .get(GET_OHS_FILTER_ENDPOINT, getFilterParams({ row: row }), token)
      .then((res) => {
        let dataCount = 0;
        Object.keys(res.data.count).map((i) => {
          dataCount = dataCount + res.data.count?.[i];
        });
        setTotalDataCount(dataCount);
        setPage(1);
        setColumnFilter(getTableColumndata([...res.data.items]));
        setListingData([...res.data.items]);
        setHasMore(res.data.newPage);
        setTotalPage(res.data.total_page);
        setPageChangeLoading(false);
      });
  };

  const rowCheckboxClicked = (e) => {
    if (exportIDS.find((i) => i === e.target.id)) {
      const filtered = exportIDS.filter((i) => i !== e.target.id);
      setExportIDS([...filtered]);
    } else {
      exportIDS.push(e.target.id);
      setExportIDS([...exportIDS]);
    }
  };

  const headerCheckboxClicked = async (e) => {
    let allID = [...exportIDS];
    let temp = [];
    await listingData.map((i) => {
      temp.push(i.id);
    });
    const filtered = temp.filter((i) => allID.find((j) => j === i) !== i);
    console.log(filtered);
    if (filtered.length === 0) {
      const deletedFilter = allID.filter(
        (i) => temp.find((j) => j === i) !== i
      );
      console.log(deletedFilter);
      setExportIDS([...deletedFilter]);
    } else {
      setExportIDS([...allID, ...filtered]);
    }
    // if (exportIDS.length === allID.length * page) {
    // } else {
    //   setExportIDS([...allID]);
    // }
  };

  console.log(exportIDS);

  const exportAPICall = async () => {
    setShowExport(false);
    setExportLoading(true);
    let column = [];
    columnOrder.map((i) => {
      if (i.isShow) {
        column.push(i.id);
      }
    });
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .post(
        OHS_EXPORT,
        JSON.stringify({
          ids: exportIDS,
          columns: column,
        }),
        token
      )
      .then((res) => {
        if (res.data.url) {
          const link = document.createElement("a");
          link.href = res.data.url;
          link.download = res.data.url.split("/").pop();
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          alert("Error encountered while downloading the file.");
        }
        setExportIDS([]);
        setExportLoading(false);
      })
      .catch((err) => {
        setExportLoading(false);
        alert("Error encountered while downloading the file.");
      });
  };

  const handleTableClick = (id) => {
    navigate(`${OHS_INSPECTION_DETAIL_ROUTE}?id=${id}`);
  };

  const SaveToUSERData = async (item) => {
    const userData = JSON.parse(localStorage.getItem("current_user"));
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    console.log(item);
    api
      .post(
        UPDATE_USER_DETAIL,
        JSON.stringify({
          widgets_config: item,
          user_id: userData.sub,
          action: "widgets_update",
        }),
        token
      )
      .then((res) => {
        console.log(res);
        if (res.status_code === 200) {
          setWidgetSaveLoading(false);
        } else {
          alert(res.message);
        }
      });
  };

  const handleSaveTable = () => {
    setWidgetSaveLoading(true);
    setShowColumnBox(false);
    setShowColumnItems(columnOrder);
    SaveToUSERData([
      {
        commonTool: commonToolEdit,
        module: modules,
        OHSTable: columnOrder,
      },
    ]);
  };

  const confirmExport = (value) => {
    setShowOption(false);
    if (exportIDS.length === 0) {
      setShowExportError(true);
    } else {
      if (value === "excel") {
        setShowExport(true);
      } else {
        setShowPdfOption(true);
      }
    }
  };

  const startExport = () => {
    exportAPICall();
  };

  const checkEmail = () => {
    const validate = emailData.recipient
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (validate === null) {
      return true;
    } else {
      return false;
    }
  };

  const emailSend = async () => {
    setShowEmailPopup(false);
    setExportLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    const validate = emailData.recipient
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (
      validate === null ||
      emailData.subject === "" ||
      emailData.content === ""
    ) {
      setEmailError(true);
      return;
    }
    api
      .post(
        `${OHS_EMAIL_ENDPOINT}${exportIDS}/send_report`,
        JSON.stringify({
          subject: emailData.subject,
          content: emailData.content,
          recipient: emailData.recipient,
        }),
        token
      )
      .then((res) => {
        if (res.details === "success") {
          setExportIDS([]);
          setEmailData({
            ...emailData,
            content: "",
            subject: "",
            recipient: "",
          });
          setExportLoading(false);
          setShowEmailSuccess(true);
        } else {
          alert(res.details);
        }
      });
  };

  const DownloadPDF = async () => {
    setShowPDFDownload(false);
    setExportLoading(true);
    const token = await getCurrentLoggedInSessionTokenWithPromise();
    api
      .post(`${OHS_EMAIL_ENDPOINT}${exportIDS}/download_pdf`, {}, token)
      .then((res) => {
        setExportLoading(false);
        if (res.details === "success") {
          window.open(res.data.url);
        } else {
          alert(res.details);
        }
      });
  };

  return (
    <>
      {exportLoading && <FullscreenLoader />}
      {widgetSaveLoading && <FullscreenLoader />}
      {loading ? (
        <Loader
          type="infinite-spinner"
          style={{
            display: "flex",
            alignItems: "center",
            width: "40px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            height: "calc(100vh - 82px)",
          }}
        />
      ) : (
        <div>
          {/* <div className="p-4 flex justify-end">
        <Button
          svgIcon={plusIcon}
          fillMode="outline"
          onClick={() => navigate(OHS_INSPECTION_CREATE_ROUTE)}
        >
          Create New
        </Button>
      </div> */}
          <MobileHeaderFiltered
            title={"OHS Listing"}
            route={CATEGORY_ROUTE}
            Class={"OHS_header"}
            icons={icons}
            button={showCreateBtn && "Create OHS form"}
            buttonLink={() => showCreateBtn && CreatePage()}
          />

          {showOption && (
            <div style={{ position: "relative" }}>
              <div className="OHS_Export_option_box">
                <div
                  className="OHS_Export_icon"
                  onMouseEnter={() =>
                    setPopupItem({
                      pdf: false,
                      excel: true,
                    })
                  }
                  onMouseLeave={() =>
                    setPopupItem({
                      ...popupItem,
                      excel: false,
                    })
                  }
                  onClick={() => {
                    confirmExport("excel");
                    setExportType("excel");
                  }}
                >
                  <span
                    class="k-icon k-font-icon k-i-file-csv"
                    style={{ fontSize: "22px" }}
                  ></span>
                  {popupItem.excel && (
                    <div className="OHS_Export_icon_tooltip_hover_main">
                      <div className="OHS_Export_icon_tooltip_hover_text">
                        CSV Export
                      </div>
                      <div className="OHS_Export_icon_tooltip_hover_tips"></div>
                    </div>
                  )}
                </div>
                {exportIDS.length === 1 && (
                  <>
                    <div
                      className="OHS_Export_icon"
                      onMouseEnter={() =>
                        setPopupItem({
                          pdf: true,
                          excel: false,
                        })
                      }
                      onMouseLeave={() =>
                        setPopupItem({
                          ...popupItem,
                          pdf: false,
                        })
                      }
                      onClick={() => {
                        confirmExport("pdf");
                        setExportType("pdf");
                      }}
                    >
                      <span
                        class="k-icon k-font-icon k-i-file-pdf"
                        style={{ fontSize: "22px" }}
                      ></span>
                      {popupItem.pdf && (
                        <div className="OHS_Export_icon_tooltip_hover_main">
                          <div className="OHS_Export_icon_tooltip_hover_text">
                            PDF Export
                          </div>
                          <div className="OHS_Export_icon_tooltip_hover_tips"></div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {showColumnBox && (
            <div
              style={{
                position: "absolute",
                right: "60px",
                top: "135px",
                zIndex: "9999",
                boxShadow: "0px 4px 4px 0px #00000040",
                background: "white",
                width: "450px",
                height: "550px",
              }}
            >
              <div style={{ position: "sticky", top: 0, zIndex: 3 }}>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "22px",
                      right: "25px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowColumnBox(false)}
                  >
                    <img src={CrossIcon} />
                  </div>
                </div>
              </div>
              <div className="OHS_Column_main_box">
                Drag line to modify columns order
              </div>
              <div
                style={{
                  marginTop: "30px",
                  overflowY: "scroll",
                  // height: "calc(100% - 105px)",
                  height: "calc(100% - 200px)",
                }}
              >
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCorners}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={columnOrder}
                    strategy={verticalListSortingStrategy}
                  >
                    {columnOrder.map((i) => (
                      <Task
                        id={i.id}
                        item={i.name}
                        key={i.id}
                        isShow={i.isShow}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              </div>
              <div className="OHS_Column_Checkbox_footer_button_box">
                {/* <div
                  className="OHS_Column_Checkbox_footer_button_cancel"
                  onClick={() => setShowColumnBox(false)}
                >
                  Cancel
                </div> */}
                <div
                  className="OHS_Column_Checkbox_footer_button_save"
                  onClick={() => handleSaveTable()}
                >
                  Save
                </div>
              </div>
            </div>
          )}
          {showExport && (
            <ConfirmMsg
              onCloseDialog={() => {
                setShowExport(false);
              }}
              msgTitle={"You are exporting Selected Observations!"}
              okBtnMsg={"Yes"}
              msgContent={`By submitting, you acknowledged all submission and documents are in accordance with requirements. Please confirm.`}
              okBtnClicked={startExport}
              cancelBtnMsg={"No"}
              cancelBtnClicked={() => {
                setShowExport(false);
              }}
            />
          )}
          {showPDFDownload && (
            <ConfirmMsg
              onCloseDialog={() => {
                setShowPDFDownload(false);
              }}
              msgTitle={"You are exporting Selected Observations as PDF!"}
              okBtnMsg={"Yes"}
              msgContent={`By submitting, you acknowledged all submission and documents are in accordance with requirements. Please confirm.`}
              okBtnClicked={DownloadPDF}
              cancelBtnMsg={"No"}
              cancelBtnClicked={() => {
                setShowPDFDownload(false);
              }}
            />
          )}
          {showEmailSuccess && (
            <ConfirmMsg
              onCloseDialog={() => {
                setShowEmailSuccess(false);
              }}
              msgTitle={"Email has been sent!"}
              okBtnMsg={"Ok"}
              msgContent={`Successfully send email.`}
              okBtnClicked={() => {
                setShowEmailSuccess(false);
              }}
              // cancelBtnMsg={"No"}
              // cancelBtnClicked={() => {
              //   setShowEmailSuccess(false);
              // }}
            />
          )}
          {showExportError && (
            <ConfirmMsg
              onCloseDialog={() => {
                setShowExportError(false);
              }}
              msgTitle={"You are not Selected any Observation!"}
              okBtnMsg={"Ok"}
              msgContent={`Please Select Observation with checkbox!`}
              okBtnClicked={() => setShowExportError(false)}
            />
          )}
          {showPdfOption && (
            <Dialog>
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "absolute",
                    right: "0",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPdfOption(false)}
                >
                  <img src={CrossIcon} />
                </div>
              </div>
              <div className="OHS_PDF_popup_box">
                <div className="OHS_PDF_popup_title">
                  Please select send email or download!
                </div>
                <div className="OHS_PDF_Download_button_box">
                  <div
                    className="OHS_PDF_Button"
                    onClick={() => {
                      setShowEmailPopup(true);
                      setShowPdfOption(false);
                    }}
                  >
                    Send Email
                  </div>
                  <div
                    className="OHS_PDF_Button"
                    onClick={() => {
                      setShowPDFDownload(true);
                      setShowPdfOption(false);
                    }}
                  >
                    Download PDF
                  </div>
                </div>
              </div>
            </Dialog>
          )}
          {showEmailPopUp && (
            <Dialog>
              <div>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      right: "0",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowEmailPopup(false)}
                  >
                    <img src={CrossIcon} />
                  </div>
                </div>
                <div className="OHS_Email_main_box">
                  <div className="OHS_Email_child_box">
                    <div className="OHS_Email_title">Email Recipient</div>
                    <div>
                      <TextBox
                        className="OHS_Email_input"
                        placeholder="XXXX@gmail.com"
                        onChange={(e) =>
                          setEmailData({
                            ...emailData,
                            recipient: e.target.value,
                          })
                        }
                      />
                      {emailError && checkEmail() ? (
                        <div className="OHS_FORM_validation">
                          Please enter valid email address
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="OHS_Email_child_box">
                    <div className="OHS_Email_title">Email Subject</div>
                    <div>
                      <TextBox
                        className="OHS_Email_input"
                        onChange={(e) =>
                          setEmailData({
                            ...emailData,
                            subject: e.target.value,
                          })
                        }
                      />
                      {emailError && emailData.subject === "" ? (
                        <div className="OHS_FORM_validation">
                          This field is required!
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="OHS_Email_child_box">
                    <div className="OHS_Email_title">Email Content</div>
                    <div>
                      <TextBox
                        className="OHS_Email_input"
                        onChange={(e) =>
                          setEmailData({
                            ...emailData,
                            content: e.target.value,
                          })
                        }
                      />
                      {emailError && emailData.content === "" ? (
                        <div className="OHS_FORM_validation">
                          This field is required!
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="OHS_Email_footer_box">
                    <div
                      className="OHS_Email_footer_cancel"
                      onClick={() => {
                        setEmailData({
                          ...emailData,
                          content: "",
                          subject: "",
                          recipient: "",
                        });
                        setShowEmailPopup(false);
                      }}
                    >
                      Cancel
                    </div>
                    <div className="OHS_Email_footer_send" onClick={emailSend}>
                      Send
                    </div>
                  </div>
                </div>
              </div>
            </Dialog>
          )}
          {/* {showFilter && (
            <OHS_Filter
              filterData={filterData}
              setShowFilter={setShowFilter}
              onChangeFilter={onChangeFilter}
              filterValue={filterValue}
              filterLoader={filterLoader}
              filterApply={filterApply}
              filterClear={filterClear}
              status={true}
              filterError={filterError}
            />
          )} */}
          {/* <div>
        <div>
          <img src={CreateIcon} />
          Create OHS Form
        </div>
      </div> */}
          {/* {update} */}
          {showSearchBox && (
            <OHSListSearch
              onChangeFilter={onChangeFilter}
              filterValue={filterValue}
              filterApply={filterApply}
              filterClear={filterClear}
              keyDownHandler={keyDownHandler}
            />
          )}
          {filterLoading ? (
            <Loader
              type="infinite-spinner"
              style={{
                display: "flex",
                alignItems: "center",
                width: "40px",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                height: "calc(100vh - 202px)",
              }}
            />
          ) : (
            <div>
              <SelectTable
                header={showColumnItems}
                maxHeight={
                  showSearchBox ? "calc(100vh - 256px)" : "calc(100vh - 202px)"
                }
                maxWidth={"calc(100vw - 100px)"}
                height={"100vh"}
                data={listingData}
                headerPadding={false}
                handleTableClick={handleTableClick}
                // handlePageChange={handlePageChange}
                hasMore={false}
                pageChangeLoading={pageChangeLoading}
                activePages={page}
                rowCheckboxClicked={rowCheckboxClicked}
                exportIDS={exportIDS}
                headerCheckboxClicked={headerCheckboxClicked}
                columnFilterData={FilterJSON}
                // columnFilterData={columnFilter}
                filteredData={filteredData}
                FilterJSON={FilterJSON}
                setColumnFilterValue={setColumnFilterValue}
                columnFilterValue={columnFilterValue}
                tableFilterApply={filterApply}
                // tableFilterClear={tableFilterClear}
              />
            </div>
          )}
          {/* <div className="OHS_pagination_box">
            Page: {page} / {totalPage}
          </div> */}
          <div className="OHS_Pagination_main_box">
            <Pagination
              pageCount={totalPage}
              totalDataCount={totalDataCount}
              handlePageChange={handlePageChange}
              showRow={true}
              rowCount={rowCount}
              activePages={page}
              handleRowChange={handleRowChange}
            />
          </div>
        </div>
      )}
    </>
  );
};
